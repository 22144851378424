.classic-mac {
  font-family: "Chicago", "Helvetica", sans-serif;
  color: #000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url('./background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.resume-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
  max-height: 100%;
}

.resume-text {
  font-size: 16px;
  line-height: 1.6;
}

.resume-columns {
  display: flex;
  gap: 40px;
  min-height: min-content;
  overflow: auto;
}

.resume-main {
  flex: 2;
  overflow: auto;
}

.resume-skills {
  flex: 1;
  overflow: auto;
}

/* Rest of the styles remain the same */
.resume-content h2 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.resume-content h3 {
  margin-bottom: 15px;
  font-size: 17px;
}

.resume-content h4 {
  margin-bottom: 10px;
  font-size: 16px;
}

.resume-content h5 {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
}

.resume-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.resume-content li {
  margin-bottom: 8px;
}
.menu-bar {
  background-color: #B2BEB5;
  border-bottom: 1px solid #000;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  color: #000;
}

.menu-bar span {
  margin-right: 30px;
  cursor: pointer;
}

.menu-bar .apple-icon {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.menu-bar .apple-icon svg {
  width: 24px;
  height: 24px;
}

.desktop {
  flex-grow: 1;
  position: relative;
  padding: 20px;
  overflow-y: auto;
}

.desktop-icon {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  left: 20px;
}

.desktop-icon:nth-child(1) { top: 20px; }
.desktop-icon:nth-child(2) { top: 160px; }
.desktop-icon:nth-child(3) { top: 300px; }
.desktop-icon:nth-child(4) { top: 440px; }
.desktop-icon:nth-child(5) { top: 580px; }

.desktop-icon .icon {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-icon .icon svg {
  width: 64px;
  height: 64px;
}

.desktop-icon .label {
  margin-top: 5px;
  font-size: 12px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  text-align: center;
  word-wrap: break-word;
  max-width: 100%;
}

.trash-can {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.trash-can .icon {
  width: 64px;
  height: 64px;
}

.trash-can .label {
  margin-top: 5px;
  font-size: 12px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  text-align: center;
}

.window {
  position: absolute;
  width: 800px;
  height: 600px;
  max-width: 90%;
  max-height: 80%;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.5);
}

.window-header {
  background-color: #000;
  color: #fff;
  padding: 2px 5px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  cursor: move;
}

.close-button {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}

.window-content {
  height: calc(100% - 24px);
  position: relative;
  overflow: hidden; /* Change from auto to hidden */
}

.text-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.text-editor .toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  background-color: #ccc;
  border-bottom: 1px solid #000;
}

.text-editor textarea {
  flex-grow: 1;
  resize: none;
  border: none;
  padding: 10px;
  font-family: 'Courier', monospace;
}

.text-editor button,
.text-editor select {
  font-size: 12px;
  padding: 2px 5px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}

* {
  touch-action: manipulation;
}

@media (max-width: 768px) {
  .menu-bar {
    height: 30px;
    font-size: 14px;
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
 
  .desktop {
    padding-top: 40px;
    padding-bottom: 70px;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
  }
 
  .window {
    position: fixed;
    width: 100vw !important;
    height: calc(100vh - 30px) !important;
    left: 0 !important;
    top: 30px !important;
    max-width: 100% !important;
    max-height: calc(100vh - 30px) !important;
    margin: 0;
    transform: none;
    border: none;
    z-index: 900;
  }
 
  .window-content {
    height: calc(100% - 30px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 10px;
  }
 
  .resume-content {
    padding: 10px;
    height: 100%;
  }
 
  .resume-columns {
    flex-direction: column;
    gap: 20px;
  }
 
  .resume-main,
  .resume-skills {
    flex: none;
    width: 100%;
  }
 
  .desktop-icon {
    width: 54px;
    height: 54px;
    position: fixed;
    left: 10px;
    margin: 0;
    z-index: 800;
  }
 
  .desktop-icon:nth-child(1) { top: 35px; }
  .desktop-icon:nth-child(2) { top: 95px; }
  .desktop-icon:nth-child(3) { top: 155px; }
  .desktop-icon:nth-child(4) { top: 215px; }
  .desktop-icon:nth-child(5) { top: 275px; }
 
  .desktop-icon .icon {
    width: 36px;
    height: 36px;
  }
 
  .desktop-icon .icon svg {
    width: 36px;
    height: 36px;
  }
 
  .desktop-icon .label {
    font-size: 10px;
    margin-top: 2px;
  }
 
  .desktop-icon[label="Trash"],
  div[label="Trash"] {
    bottom: 5px !important;
    right: 5px !important;
    top: auto !important;
    left: auto !important;
    width: 54px !important;
    height: 54px !important;
    z-index: 9999 !important;
    transform: none !important;
    margin: 0 !important;
  }
 
  .close-button {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
  }
 
  .resume-content h2 {
    font-size: 16px;
  }
 
  .resume-content h3 {
    font-size: 15px;
  }
 
  .resume-content h4,
  .resume-content h5 {
    font-size: 14px;
  }
 
  .resume-content li {
    font-size: 14px;
    margin-bottom: 6px;
  }
 
  .window-header {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
 }